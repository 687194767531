import { Class, Field, MetaRuntime, Type } from "@/wab/shared/model/model-meta";
const CLASSES = [
  new Class({
    name: "Type",
    base: null,
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "PrimitiveType",
    base: "Type",
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "Scalar",
    base: "PrimitiveType",
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "Num",
    base: "Scalar",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["num"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "Text",
    base: "Scalar",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["text"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "BoolType",
    base: "Scalar",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["bool"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "AnyType",
    base: "PrimitiveType",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["any"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "Choice",
    base: "PrimitiveType",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["choice"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "options",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "List",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "List",
              params: [
                new Type({
                  type: "Map",
                  params: [
                    new Type({
                      type: "String",
                      params: [],
                    }),
                    new Type({
                      type: "Or",
                      params: [
                        new Type({
                          type: "String",
                          params: [],
                        }),
                        new Type({
                          type: "Number",
                          params: [],
                        }),
                        new Type({
                          type: "Bool",
                          params: [],
                        }),
                      ],
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Img",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["img"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "ComponentInstance",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["instance"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "component",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "PlumeInstance",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["plumeInstance"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "plumeType",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "RenderableType",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["renderable"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "params",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Or",
              params: [
                new Type({
                  type: "PlumeInstance",
                  params: [],
                }),
                new Type({
                  type: "ComponentInstance",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "allowRootWrapper",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Bool",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "HrefType",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["href"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "TargetType",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["target"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "RenderFuncType",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["renderFunc"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "params",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ArgType",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "allowed",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ComponentInstance",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "allowRootWrapper",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Bool",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "QueryData",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["queryData"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "DateString",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["dateString"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "DateRangeStrings",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["dateRangeStrings"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "FunctionType",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["func"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "params",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ArgType",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ArgType",
    base: "Type",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["arg"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "argName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "displayName",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "PrimitiveType",
              params: [],
            }),
            new Type({
              type: "Img",
              params: [],
            }),
            new Type({
              type: "HrefType",
              params: [],
            }),
            new Type({
              type: "TargetType",
              params: [],
            }),
            new Type({
              type: "DateString",
              params: [],
            }),
            new Type({
              type: "DateRangeStrings",
              params: [],
            }),
            new Type({
              type: "QueryData",
              params: [],
            }),
            new Type({
              type: "StylePropType",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StylePropType",
    base: "Type",
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "ClassNamePropType",
    base: "StylePropType",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["className"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "selectors",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "LabeledSelector",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultStyles",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StyleScopeClassNamePropType",
    base: "StylePropType",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["styleScopeClassName"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "scopeName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "DefaultStylesClassNamePropType",
    base: "StylePropType",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["defaultStylesClassName"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "includeTagStyles",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "DefaultStylesPropType",
    base: "StylePropType",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["defaultStyles"],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "ColorPropType",
    base: "StylePropType",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "StringLiteral",
          params: ["color"],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "noDeref",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "VariantedValue",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "variants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "value",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StyleToken",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "type",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "value",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "variantedValues",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "VariantedValue",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "isRegistered",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "regKey",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "HostLessPackageInfo",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "npmPkg",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "cssImport",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "deps",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "registerCalls",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "minimumReactVersion",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Site",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "components",
        type: new Type({
          type: "Set",
          params: [
            new Type({
              type: "Component",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "arenas",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Arena",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "pageArenas",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "PageArena",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "componentArenas",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ComponentArena",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "globalVariantGroups",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "GlobalVariantGroup",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "userManagedFonts",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "globalVariant",
        type: new Type({
          type: "Variant",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "styleTokens",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "StyleToken",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "mixins",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Mixin",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "themes",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Theme",
              params: [],
            }),
          ],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "activeTheme",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Theme",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "imageAssets",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ImageAsset",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "projectDependencies",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ProjectDependency",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "activeScreenVariantGroup",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "GlobalVariantGroup",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "flags",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Or",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
                new Type({
                  type: "Bool",
                  params: [],
                }),
                new Type({
                  type: "Optional",
                  params: [
                    new Type({
                      type: "Number",
                      params: [],
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "hostLessPackageInfo",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "HostLessPackageInfo",
              params: [],
            }),
          ],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "globalContexts",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "TplComponent",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "splits",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Split",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultComponents",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Component",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "defaultPageRoleId",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "pageWrapper",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Component",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "customFunctions",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "CustomFunction",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "codeLibraries",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "CodeLibrary",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ArenaFrameGrid",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "rows",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ArenaFrameRow",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ArenaFrameRow",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "cols",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ArenaFrameCell",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "rowKey",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "VariantGroup",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "Variant",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ArenaFrameCell",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "frame",
        type: new Type({
          type: "ArenaFrame",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "cellKey",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "Variant",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "List",
                  params: [
                    new Type({
                      type: "Variant",
                      params: [],
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ComponentArena",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "component",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
      new Field({
        name: "_focusedFrame",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "ArenaFrame",
              params: [],
            }),
          ],
        }),
        annotations: ["Transient"],
      }),
      new Field({
        name: "matrix",
        type: new Type({
          type: "ArenaFrameGrid",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "customMatrix",
        type: new Type({
          type: "ArenaFrameGrid",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "PageArena",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "component",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
      new Field({
        name: "_focusedFrame",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "ArenaFrame",
              params: [],
            }),
          ],
        }),
        annotations: ["Transient"],
      }),
      new Field({
        name: "matrix",
        type: new Type({
          type: "ArenaFrameGrid",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "customMatrix",
        type: new Type({
          type: "ArenaFrameGrid",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Arena",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "children",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ArenaChild",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ArenaChild",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "top",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Number",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "left",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Number",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ArenaFrame",
    base: "ArenaChild",
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "width",
        type: new Type({
          type: "Number",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "height",
        type: new Type({
          type: "Number",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "container",
        type: new Type({
          type: "TplComponent",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "lang",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "pinnedVariants",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Bool",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "targetVariants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "pinnedGlobalVariants",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Bool",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "targetGlobalVariants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "viewMode",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["stretch"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["centered"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "bgColor",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "CustomFunction",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "importPath",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "importName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultExport",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "namespace",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "CodeLibrary",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "importPath",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "jsIdentifier",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "importType",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["default"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["namespace"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["named"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "namedImport",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "isSyntheticDefaultImport",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StyleNode",
    base: null,
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "RuleSet",
    base: "StyleNode",
    concrete: false,
    fields: [
      new Field({
        name: "values",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "mixins",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Mixin",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "Rule",
    base: "StyleNode",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "values",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "VariantedRuleSet",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "variants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: ["Const", "WeakRef"],
      }),
      new Field({
        name: "rs",
        type: new Type({
          type: "RuleSet",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Mixin",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "rs",
        type: new Type({
          type: "RuleSet",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "preview",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "forTheme",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "variantedRs",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "VariantedRuleSet",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Theme",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "defaultStyle",
        type: new Type({
          type: "Mixin",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "styles",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ThemeStyle",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "layout",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "ThemeLayoutSettings",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "addItemPrefs",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "RuleSet",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "active",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "ThemeStyle",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "selector",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "style",
        type: new Type({
          type: "Mixin",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ThemeLayoutSettings",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "rs",
        type: new Type({
          type: "RuleSet",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ProjectDependency",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "pkgId",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "projectId",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "version",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "site",
        type: new Type({
          type: "Site",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ImageAsset",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["picture"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["icon"],
            }),
          ],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "dataUri",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "width",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Number",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "height",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Number",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "aspectRatio",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Number",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "TplNode",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "parent",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "TplNode",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "locked",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Bool",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "vsettings",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "VariantSetting",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "TplTag",
    base: "TplNode",
    concrete: false,
    fields: [
      new Field({
        name: "tag",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "children",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "TplNode",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["text"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["image"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["column"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["columns"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["other"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "codeGenType",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "StringLiteral",
                  params: ["auto"],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "StringLiteral",
                  params: ["alwaysTag"],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "StringLiteral",
                  params: ["noTag"],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "columnsSetting",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "ColumnsSetting",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "TplComponent",
    base: "TplNode",
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "component",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "TplSlot",
    base: "TplNode",
    concrete: false,
    fields: [
      new Field({
        name: "param",
        type: new Type({
          type: "SlotParam",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
      new Field({
        name: "defaultContents",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "TplNode",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ColumnsSetting",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "screenBreakpoint",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "PageMeta",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "path",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "params",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "query",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "title",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "description",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "canonical",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "roleId",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "openGraphImage",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "ImageAsset",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ComponentDataQuery",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "op",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "DataSourceOpExpr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "CodeComponentHelper",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "importPath",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "importName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultExport",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "CodeComponentVariantMeta",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "cssSelector",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "displayName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "CodeComponentMeta",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "importPath",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultExport",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "displayName",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "importName",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "description",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "section",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "thumbnailUrl",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "classNameProp",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "refProp",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultStyles",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "RuleSet",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultDisplay",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "isHostLess",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "isContext",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "isAttachment",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "providesData",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "hasRef",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "isRepeatable",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "styleSections",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Bool",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "helpers",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "CodeComponentHelper",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultSlotContents",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Any",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "variants",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "CodeComponentVariantMeta",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Component",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "params",
        type: new Type({
          type: "Set",
          params: [
            new Type({
              type: "Param",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "states",
        type: new Type({
          type: "Set",
          params: [
            new Type({
              type: "State",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "tplTree",
        type: new Type({
          type: "TplNode",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "editableByContentEditor",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "hiddenFromContentEditor",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "variants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "variantGroups",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ComponentVariantGroup",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "pageMeta",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "PageMeta",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "codeComponentMeta",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "CodeComponentMeta",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["plain"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["page"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["code"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["frame"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "subComps",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Component",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "superComp",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Component",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "plumeInfo",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "PlumeInfo",
              params: [],
            }),
          ],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "templateInfo",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "ComponentTemplateInfo",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "metadata",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "dataQueries",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "ComponentDataQuery",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "figmaMappings",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "FigmaComponentMapping",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "alwaysAutoName",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "trapsFocus",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "NameArg",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "expr",
        type: new Type({
          type: "Expr",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "PlumeInfo",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ComponentTemplateInfo",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "projectId",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "componentId",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Variant",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "selectors",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "List",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "codeComponentName",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "codeComponentVariantKeys",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "List",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "parent",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "VariantGroup",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "mediaQuery",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "description",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "forTpl",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "TplTag",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "TplComponent",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "TplSlot",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "VariantGroup",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "variants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "multi",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "GlobalVariantGroup",
    base: "VariantGroup",
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["global-screen"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["global-user-defined"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "param",
        type: new Type({
          type: "GlobalVariantGroupParam",
          params: [],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "ComponentVariantGroup",
    base: "VariantGroup",
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "StringLiteral",
          params: ["component"],
        }),
        annotations: [],
      }),
      new Field({
        name: "param",
        type: new Type({
          type: "StateParam",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
      new Field({
        name: "linkedState",
        type: new Type({
          type: "VariantGroupState",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "VariantSetting",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "variants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "args",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Arg",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "attrs",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Expr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "rs",
        type: new Type({
          type: "RuleSet",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "dataCond",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "CustomCode",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "ObjectPath",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "dataRep",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Rep",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "text",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "RichText",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "columnsConfig",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "ColumnsConfig",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Interaction",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "interactionName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "actionName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "args",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "NameArg",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "condExpr",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "CustomCode",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "ObjectPath",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "conditionalMode",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["always"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["never"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["expression"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "parent",
        type: new Type({
          type: "EventHandler",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ColumnsConfig",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "breakUpRows",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "colsSizes",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Number",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Marker",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "position",
        type: new Type({
          type: "Number",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "length",
        type: new Type({
          type: "Number",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StyleMarker",
    base: "Marker",
    concrete: false,
    fields: [
      new Field({
        name: "rs",
        type: new Type({
          type: "RuleSet",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "NodeMarker",
    base: "Marker",
    concrete: false,
    fields: [
      new Field({
        name: "tpl",
        type: new Type({
          type: "TplNode",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "RichText",
    base: null,
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "RawText",
    base: "RichText",
    concrete: false,
    fields: [
      new Field({
        name: "markers",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Marker",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "text",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "ExprText",
    base: "RichText",
    concrete: false,
    fields: [
      new Field({
        name: "expr",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "CustomCode",
              params: [],
            }),
            new Type({
              type: "ObjectPath",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "html",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Var",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
    ],
  }),
  new Class({
    name: "BindingStruct",
    base: null,
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "Rep",
    base: "BindingStruct",
    concrete: false,
    fields: [
      new Field({
        name: "element",
        type: new Type({
          type: "Var",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "index",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Var",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "collection",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "CustomCode",
              params: [],
            }),
            new Type({
              type: "ObjectPath",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Param",
    base: "BindingStruct",
    concrete: false,
    fields: [
      new Field({
        name: "variable",
        type: new Type({
          type: "Var",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "enumValues",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Or",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
                new Type({
                  type: "Bool",
                  params: [],
                }),
                new Type({
                  type: "Number",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "origin",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "Number",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "exportType",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["Internal"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["External"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["ToolsOnly"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultExpr",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Expr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "previewExpr",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Expr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "propEffect",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "description",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "displayName",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "about",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "isRepeated",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Bool",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "isMainContentSlot",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "required",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "mergeWithParent",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "isLocalizable",
        type: new Type({
          type: "Bool",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "SlotParam",
    base: "Param",
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "RenderableType",
              params: [],
            }),
            new Type({
              type: "RenderFuncType",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "tplSlot",
        type: new Type({
          type: "TplSlot",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "StateParam",
    base: "Param",
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "PrimitiveType",
              params: [],
            }),
            new Type({
              type: "Img",
              params: [],
            }),
            new Type({
              type: "HrefType",
              params: [],
            }),
            new Type({
              type: "TargetType",
              params: [],
            }),
            new Type({
              type: "DateString",
              params: [],
            }),
            new Type({
              type: "DateRangeStrings",
              params: [],
            }),
            new Type({
              type: "QueryData",
              params: [],
            }),
            new Type({
              type: "StylePropType",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "state",
        type: new Type({
          type: "State",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "GlobalVariantGroupParam",
    base: "Param",
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Text",
              params: [],
            }),
            new Type({
              type: "BoolType",
              params: [],
            }),
            new Type({
              type: "Choice",
              params: [],
            }),
            new Type({
              type: "AnyType",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StateChangeHandlerParam",
    base: "Param",
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "FunctionType",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "state",
        type: new Type({
          type: "State",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "PropParam",
    base: "Param",
    concrete: false,
    fields: [
      new Field({
        name: "type",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "PrimitiveType",
              params: [],
            }),
            new Type({
              type: "Img",
              params: [],
            }),
            new Type({
              type: "HrefType",
              params: [],
            }),
            new Type({
              type: "TargetType",
              params: [],
            }),
            new Type({
              type: "DateString",
              params: [],
            }),
            new Type({
              type: "DateRangeStrings",
              params: [],
            }),
            new Type({
              type: "QueryData",
              params: [],
            }),
            new Type({
              type: "FunctionType",
              params: [],
            }),
            new Type({
              type: "StylePropType",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Arg",
    base: "BindingStruct",
    concrete: false,
    fields: [
      new Field({
        name: "param",
        type: new Type({
          type: "Param",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "expr",
        type: new Type({
          type: "Expr",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "Expr",
    base: null,
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "RenderExpr",
    base: "Expr",
    concrete: true,
    fields: [
      new Field({
        name: "tpl",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "TplNode",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "VirtualRenderExpr",
    base: "RenderExpr",
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "CustomCode",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "code",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "fallback",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Expr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "DataSourceOpExpr",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "parent",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "QueryRef",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "sourceId",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "opId",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "opName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "templates",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "DataSourceTemplate",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "cacheKey",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "TemplatedString",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "queryInvalidation",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "QueryInvalidationExpr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "roleId",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "VarRef",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "variable",
        type: new Type({
          type: "Var",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "TplRef",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "tpl",
        type: new Type({
          type: "TplNode",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "StyleTokenRef",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "token",
        type: new Type({
          type: "StyleToken",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ImageAssetRef",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "asset",
        type: new Type({
          type: "ImageAsset",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "PageHref",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "page",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "params",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Or",
              params: [
                new Type({
                  type: "TemplatedString",
                  params: [],
                }),
                new Type({
                  type: "CustomCode",
                  params: [],
                }),
                new Type({
                  type: "ObjectPath",
                  params: [],
                }),
                new Type({
                  type: "VarRef",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "VariantsRef",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "variants",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Variant",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ObjectPath",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "path",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Or",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
                new Type({
                  type: "Number",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "fallback",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Expr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "EventHandler",
    base: "Expr",
    concrete: true,
    fields: [
      new Field({
        name: "interactions",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Interaction",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "GenericEventHandler",
    base: "EventHandler",
    concrete: false,
    fields: [
      new Field({
        name: "handlerType",
        type: new Type({
          type: "FunctionType",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "FunctionArg",
    base: "Expr",
    concrete: true,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "argType",
        type: new Type({
          type: "ArgType",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "expr",
        type: new Type({
          type: "Expr",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StrongFunctionArg",
    base: "FunctionArg",
    concrete: false,
    fields: [
      new Field({
        name: "argType",
        type: new Type({
          type: "ArgType",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "CollectionExpr",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "exprs",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "Expr",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "MapExpr",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "mapExpr",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Expr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "StyleExpr",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "styles",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "SelectorRuleSet",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "TemplatedString",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "text",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Or",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
                new Type({
                  type: "ObjectPath",
                  params: [],
                }),
                new Type({
                  type: "CustomCode",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "FunctionExpr",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "argNames",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "bodyExpr",
        type: new Type({
          type: "Expr",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "QueryInvalidationExpr",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "invalidationQueries",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "Or",
              params: [
                new Type({
                  type: "QueryRef",
                  params: [],
                }),
                new Type({
                  type: "String",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "invalidationKeys",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "CustomCode",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "ObjectPath",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "CompositeExpr",
    base: "Expr",
    concrete: false,
    fields: [
      new Field({
        name: "hostLiteral",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "substitutions",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "Expr",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "SelectorRuleSet",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "rs",
        type: new Type({
          type: "RuleSet",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "selector",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "LabeledSelector",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "selector",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "label",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "defaultStyles",
        type: new Type({
          type: "Map",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "DataSourceTemplate",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "fieldType",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "value",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "TemplatedString",
              params: [],
            }),
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "bindings",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "Map",
              params: [
                new Type({
                  type: "String",
                  params: [],
                }),
                new Type({
                  type: "Or",
                  params: [
                    new Type({
                      type: "TemplatedString",
                      params: [],
                    }),
                    new Type({
                      type: "CustomCode",
                      params: [],
                    }),
                    new Type({
                      type: "ObjectPath",
                      params: [],
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "QueryRef",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "ref",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "TplNode",
              params: [],
            }),
            new Type({
              type: "ComponentDataQuery",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "State",
    base: null,
    concrete: true,
    fields: [
      new Field({
        name: "param",
        type: new Type({
          type: "StateParam",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
      new Field({
        name: "accessType",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["private"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["readonly"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["writable"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "variableType",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["text"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["number"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["boolean"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["array"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["object"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["variant"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["dateString"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["dateRangeStrings"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "onChangeParam",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StateChangeHandlerParam",
              params: [],
            }),
            new Type({
              type: "PropParam",
              params: [],
            }),
          ],
        }),
        annotations: ["Const", "WeakRef"],
      }),
      new Field({
        name: "tplNode",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "TplComponent",
                  params: [],
                }),
              ],
            }),
            new Type({
              type: "Optional",
              params: [
                new Type({
                  type: "TplTag",
                  params: [],
                }),
              ],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "implicitState",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "State",
              params: [],
            }),
          ],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "NamedState",
    base: "State",
    concrete: false,
    fields: [
      new Field({
        name: "variableType",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["text"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["number"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["boolean"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["array"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["object"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["dateString"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["dateRangeStrings"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "VariantGroupState",
    base: "State",
    concrete: false,
    fields: [
      new Field({
        name: "variantGroup",
        type: new Type({
          type: "ComponentVariantGroup",
          params: [],
        }),
        annotations: ["Const", "WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "Split",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "splitType",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["experiment"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["segment"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["schedule"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "slices",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "SplitSlice",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "status",
        type: new Type({
          type: "Or",
          params: [
            new Type({
              type: "StringLiteral",
              params: ["new"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["running"],
            }),
            new Type({
              type: "StringLiteral",
              params: ["stopped"],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "targetEvents",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "description",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "externalId",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "SplitSlice",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "uuid",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: ["Const"],
      }),
      new Field({
        name: "name",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
      new Field({
        name: "externalId",
        type: new Type({
          type: "Optional",
          params: [
            new Type({
              type: "String",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
      new Field({
        name: "contents",
        type: new Type({
          type: "List",
          params: [
            new Type({
              type: "SplitContent",
              params: [],
            }),
          ],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "RandomSplitSlice",
    base: "SplitSlice",
    concrete: false,
    fields: [
      new Field({
        name: "prob",
        type: new Type({
          type: "Number",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "SegmentSplitSlice",
    base: "SplitSlice",
    concrete: false,
    fields: [
      new Field({
        name: "cond",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
  new Class({
    name: "SplitContent",
    base: null,
    concrete: false,
    fields: [],
  }),
  new Class({
    name: "GlobalVariantSplitContent",
    base: "SplitContent",
    concrete: false,
    fields: [
      new Field({
        name: "group",
        type: new Type({
          type: "GlobalVariantGroup",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "variant",
        type: new Type({
          type: "Variant",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ComponentVariantSplitContent",
    base: "SplitContent",
    concrete: false,
    fields: [
      new Field({
        name: "component",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "group",
        type: new Type({
          type: "ComponentVariantGroup",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "variant",
        type: new Type({
          type: "Variant",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "ComponentSwapSplitContent",
    base: "SplitContent",
    concrete: false,
    fields: [
      new Field({
        name: "fromComponent",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
      new Field({
        name: "toComponent",
        type: new Type({
          type: "Component",
          params: [],
        }),
        annotations: ["WeakRef"],
      }),
    ],
  }),
  new Class({
    name: "FigmaComponentMapping",
    base: null,
    concrete: false,
    fields: [
      new Field({
        name: "figmaComponentName",
        type: new Type({
          type: "String",
          params: [],
        }),
        annotations: [],
      }),
    ],
  }),
];
export const CLASSES_STRUCT = {
  Type: { name: "Type", concrete: false, fields: {} },
  PrimitiveType: {
    name: "PrimitiveType",
    base: "Type",
    concrete: false,
    fields: {},
  },
  Scalar: {
    name: "Scalar",
    base: "PrimitiveType",
    concrete: false,
    fields: {},
  },
  Num: {
    name: "Num",
    base: "Scalar",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["num"] },
        annotations: ["Const"],
      },
    },
  },
  Text: {
    name: "Text",
    base: "Scalar",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["text"] },
        annotations: ["Const"],
      },
    },
  },
  BoolType: {
    name: "BoolType",
    base: "Scalar",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["bool"] },
        annotations: ["Const"],
      },
    },
  },
  AnyType: {
    name: "AnyType",
    base: "PrimitiveType",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["any"] },
        annotations: ["Const"],
      },
    },
  },
  Choice: {
    name: "Choice",
    base: "PrimitiveType",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["choice"] },
        annotations: ["Const"],
      },
      options: {
        name: "options",
        type: {
          type: "Or",
          params: [
            { type: "List", params: [{ type: "String", params: [] }] },
            {
              type: "List",
              params: [
                {
                  type: "Map",
                  params: [
                    { type: "String", params: [] },
                    {
                      type: "Or",
                      params: [
                        { type: "String", params: [] },
                        { type: "Number", params: [] },
                        { type: "Bool", params: [] },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        annotations: [],
      },
    },
  },
  Img: {
    name: "Img",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["img"] },
        annotations: ["Const"],
      },
    },
  },
  ComponentInstance: {
    name: "ComponentInstance",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["instance"] },
        annotations: ["Const"],
      },
      component: {
        name: "component",
        type: { type: "Component", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  PlumeInstance: {
    name: "PlumeInstance",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["plumeInstance"] },
        annotations: ["Const"],
      },
      plumeType: {
        name: "plumeType",
        type: { type: "String", params: [] },
        annotations: [],
      },
    },
  },
  RenderableType: {
    name: "RenderableType",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["renderable"] },
        annotations: ["Const"],
      },
      params: {
        name: "params",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "PlumeInstance", params: [] },
                { type: "ComponentInstance", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      allowRootWrapper: {
        name: "allowRootWrapper",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
    },
  },
  HrefType: {
    name: "HrefType",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["href"] },
        annotations: ["Const"],
      },
    },
  },
  TargetType: {
    name: "TargetType",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["target"] },
        annotations: ["Const"],
      },
    },
  },
  RenderFuncType: {
    name: "RenderFuncType",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["renderFunc"] },
        annotations: ["Const"],
      },
      params: {
        name: "params",
        type: { type: "List", params: [{ type: "ArgType", params: [] }] },
        annotations: [],
      },
      allowed: {
        name: "allowed",
        type: {
          type: "List",
          params: [{ type: "ComponentInstance", params: [] }],
        },
        annotations: [],
      },
      allowRootWrapper: {
        name: "allowRootWrapper",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
    },
  },
  QueryData: {
    name: "QueryData",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["queryData"] },
        annotations: ["Const"],
      },
    },
  },
  DateString: {
    name: "DateString",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["dateString"] },
        annotations: ["Const"],
      },
    },
  },
  DateRangeStrings: {
    name: "DateRangeStrings",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["dateRangeStrings"] },
        annotations: ["Const"],
      },
    },
  },
  FunctionType: {
    name: "FunctionType",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["func"] },
        annotations: ["Const"],
      },
      params: {
        name: "params",
        type: { type: "List", params: [{ type: "ArgType", params: [] }] },
        annotations: [],
      },
    },
  },
  ArgType: {
    name: "ArgType",
    base: "Type",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["arg"] },
        annotations: ["Const"],
      },
      argName: {
        name: "argName",
        type: { type: "String", params: [] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "PrimitiveType", params: [] },
            { type: "Img", params: [] },
            { type: "HrefType", params: [] },
            { type: "TargetType", params: [] },
            { type: "DateString", params: [] },
            { type: "DateRangeStrings", params: [] },
            { type: "QueryData", params: [] },
            { type: "StylePropType", params: [] },
          ],
        },
        annotations: [],
      },
    },
  },
  StylePropType: {
    name: "StylePropType",
    base: "Type",
    concrete: false,
    fields: {},
  },
  ClassNamePropType: {
    name: "ClassNamePropType",
    base: "StylePropType",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["className"] },
        annotations: ["Const"],
      },
      selectors: {
        name: "selectors",
        type: {
          type: "List",
          params: [{ type: "LabeledSelector", params: [] }],
        },
        annotations: [],
      },
      defaultStyles: {
        name: "defaultStyles",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "String", params: [] },
          ],
        },
        annotations: [],
      },
    },
  },
  StyleScopeClassNamePropType: {
    name: "StyleScopeClassNamePropType",
    base: "StylePropType",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["styleScopeClassName"] },
        annotations: ["Const"],
      },
      scopeName: {
        name: "scopeName",
        type: { type: "String", params: [] },
        annotations: [],
      },
    },
  },
  DefaultStylesClassNamePropType: {
    name: "DefaultStylesClassNamePropType",
    base: "StylePropType",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["defaultStylesClassName"] },
        annotations: ["Const"],
      },
      includeTagStyles: {
        name: "includeTagStyles",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  DefaultStylesPropType: {
    name: "DefaultStylesPropType",
    base: "StylePropType",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["defaultStyles"] },
        annotations: ["Const"],
      },
    },
  },
  ColorPropType: {
    name: "ColorPropType",
    base: "StylePropType",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "StringLiteral", params: ["color"] },
        annotations: ["Const"],
      },
      noDeref: {
        name: "noDeref",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  VariantedValue: {
    name: "VariantedValue",
    concrete: false,
    fields: {
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: ["WeakRef"],
      },
      value: {
        name: "value",
        type: { type: "String", params: [] },
        annotations: [],
      },
    },
  },
  StyleToken: {
    name: "StyleToken",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      type: {
        name: "type",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      value: {
        name: "value",
        type: { type: "String", params: [] },
        annotations: [],
      },
      variantedValues: {
        name: "variantedValues",
        type: {
          type: "List",
          params: [{ type: "VariantedValue", params: [] }],
        },
        annotations: [],
      },
      isRegistered: {
        name: "isRegistered",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      regKey: {
        name: "regKey",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  HostLessPackageInfo: {
    name: "HostLessPackageInfo",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      npmPkg: {
        name: "npmPkg",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      cssImport: {
        name: "cssImport",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      deps: {
        name: "deps",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      registerCalls: {
        name: "registerCalls",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      minimumReactVersion: {
        name: "minimumReactVersion",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  Site: {
    name: "Site",
    concrete: false,
    fields: {
      components: {
        name: "components",
        type: { type: "Set", params: [{ type: "Component", params: [] }] },
        annotations: [],
      },
      arenas: {
        name: "arenas",
        type: { type: "List", params: [{ type: "Arena", params: [] }] },
        annotations: [],
      },
      pageArenas: {
        name: "pageArenas",
        type: { type: "List", params: [{ type: "PageArena", params: [] }] },
        annotations: [],
      },
      componentArenas: {
        name: "componentArenas",
        type: {
          type: "List",
          params: [{ type: "ComponentArena", params: [] }],
        },
        annotations: [],
      },
      globalVariantGroups: {
        name: "globalVariantGroups",
        type: {
          type: "List",
          params: [{ type: "GlobalVariantGroup", params: [] }],
        },
        annotations: [],
      },
      userManagedFonts: {
        name: "userManagedFonts",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      globalVariant: {
        name: "globalVariant",
        type: { type: "Variant", params: [] },
        annotations: ["Const"],
      },
      styleTokens: {
        name: "styleTokens",
        type: { type: "List", params: [{ type: "StyleToken", params: [] }] },
        annotations: [],
      },
      mixins: {
        name: "mixins",
        type: { type: "List", params: [{ type: "Mixin", params: [] }] },
        annotations: [],
      },
      themes: {
        name: "themes",
        type: { type: "List", params: [{ type: "Theme", params: [] }] },
        annotations: ["Const"],
      },
      activeTheme: {
        name: "activeTheme",
        type: { type: "Optional", params: [{ type: "Theme", params: [] }] },
        annotations: ["WeakRef"],
      },
      imageAssets: {
        name: "imageAssets",
        type: { type: "List", params: [{ type: "ImageAsset", params: [] }] },
        annotations: [],
      },
      projectDependencies: {
        name: "projectDependencies",
        type: {
          type: "List",
          params: [{ type: "ProjectDependency", params: [] }],
        },
        annotations: ["WeakRef"],
      },
      activeScreenVariantGroup: {
        name: "activeScreenVariantGroup",
        type: {
          type: "Optional",
          params: [{ type: "GlobalVariantGroup", params: [] }],
        },
        annotations: ["WeakRef"],
      },
      flags: {
        name: "flags",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Bool", params: [] },
                { type: "Optional", params: [{ type: "Number", params: [] }] },
              ],
            },
          ],
        },
        annotations: [],
      },
      hostLessPackageInfo: {
        name: "hostLessPackageInfo",
        type: {
          type: "Optional",
          params: [{ type: "HostLessPackageInfo", params: [] }],
        },
        annotations: ["Const"],
      },
      globalContexts: {
        name: "globalContexts",
        type: { type: "List", params: [{ type: "TplComponent", params: [] }] },
        annotations: [],
      },
      splits: {
        name: "splits",
        type: { type: "List", params: [{ type: "Split", params: [] }] },
        annotations: [],
      },
      defaultComponents: {
        name: "defaultComponents",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "Component", params: [] },
          ],
        },
        annotations: ["WeakRef"],
      },
      defaultPageRoleId: {
        name: "defaultPageRoleId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      pageWrapper: {
        name: "pageWrapper",
        type: { type: "Optional", params: [{ type: "Component", params: [] }] },
        annotations: ["WeakRef"],
      },
      customFunctions: {
        name: "customFunctions",
        type: {
          type: "List",
          params: [{ type: "CustomFunction", params: [] }],
        },
        annotations: [],
      },
      codeLibraries: {
        name: "codeLibraries",
        type: { type: "List", params: [{ type: "CodeLibrary", params: [] }] },
        annotations: [],
      },
    },
  },
  ArenaFrameGrid: {
    name: "ArenaFrameGrid",
    concrete: false,
    fields: {
      rows: {
        name: "rows",
        type: { type: "List", params: [{ type: "ArenaFrameRow", params: [] }] },
        annotations: [],
      },
    },
  },
  ArenaFrameRow: {
    name: "ArenaFrameRow",
    concrete: false,
    fields: {
      cols: {
        name: "cols",
        type: {
          type: "List",
          params: [{ type: "ArenaFrameCell", params: [] }],
        },
        annotations: [],
      },
      rowKey: {
        name: "rowKey",
        type: {
          type: "Or",
          params: [
            {
              type: "Optional",
              params: [{ type: "VariantGroup", params: [] }],
            },
            { type: "Optional", params: [{ type: "Variant", params: [] }] },
          ],
        },
        annotations: ["WeakRef"],
      },
    },
  },
  ArenaFrameCell: {
    name: "ArenaFrameCell",
    concrete: false,
    fields: {
      frame: {
        name: "frame",
        type: { type: "ArenaFrame", params: [] },
        annotations: ["Const"],
      },
      cellKey: {
        name: "cellKey",
        type: {
          type: "Or",
          params: [
            { type: "Optional", params: [{ type: "Variant", params: [] }] },
            {
              type: "Optional",
              params: [
                { type: "List", params: [{ type: "Variant", params: [] }] },
              ],
            },
          ],
        },
        annotations: ["WeakRef"],
      },
    },
  },
  ComponentArena: {
    name: "ComponentArena",
    concrete: false,
    fields: {
      component: {
        name: "component",
        type: { type: "Component", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      _focusedFrame: {
        name: "_focusedFrame",
        type: {
          type: "Optional",
          params: [{ type: "ArenaFrame", params: [] }],
        },
        annotations: ["Transient"],
      },
      matrix: {
        name: "matrix",
        type: { type: "ArenaFrameGrid", params: [] },
        annotations: [],
      },
      customMatrix: {
        name: "customMatrix",
        type: { type: "ArenaFrameGrid", params: [] },
        annotations: [],
      },
    },
  },
  PageArena: {
    name: "PageArena",
    concrete: false,
    fields: {
      component: {
        name: "component",
        type: { type: "Component", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      _focusedFrame: {
        name: "_focusedFrame",
        type: {
          type: "Optional",
          params: [{ type: "ArenaFrame", params: [] }],
        },
        annotations: ["Transient"],
      },
      matrix: {
        name: "matrix",
        type: { type: "ArenaFrameGrid", params: [] },
        annotations: [],
      },
      customMatrix: {
        name: "customMatrix",
        type: { type: "ArenaFrameGrid", params: [] },
        annotations: [],
      },
    },
  },
  Arena: {
    name: "Arena",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      children: {
        name: "children",
        type: { type: "List", params: [{ type: "ArenaChild", params: [] }] },
        annotations: [],
      },
    },
  },
  ArenaChild: {
    name: "ArenaChild",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      top: {
        name: "top",
        type: { type: "Optional", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
      left: {
        name: "left",
        type: { type: "Optional", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
    },
  },
  ArenaFrame: {
    name: "ArenaFrame",
    base: "ArenaChild",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      width: {
        name: "width",
        type: { type: "Number", params: [] },
        annotations: [],
      },
      height: {
        name: "height",
        type: { type: "Number", params: [] },
        annotations: [],
      },
      container: {
        name: "container",
        type: { type: "TplComponent", params: [] },
        annotations: [],
      },
      lang: {
        name: "lang",
        type: { type: "String", params: [] },
        annotations: [],
      },
      pinnedVariants: {
        name: "pinnedVariants",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "Bool", params: [] },
          ],
        },
        annotations: [],
      },
      targetVariants: {
        name: "targetVariants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: ["WeakRef"],
      },
      pinnedGlobalVariants: {
        name: "pinnedGlobalVariants",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "Bool", params: [] },
          ],
        },
        annotations: [],
      },
      targetGlobalVariants: {
        name: "targetGlobalVariants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: ["WeakRef"],
      },
      viewMode: {
        name: "viewMode",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["stretch"] },
            { type: "StringLiteral", params: ["centered"] },
          ],
        },
        annotations: [],
      },
      bgColor: {
        name: "bgColor",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      top: {
        name: "top",
        type: { type: "Optional", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
      left: {
        name: "left",
        type: { type: "Optional", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
    },
  },
  CustomFunction: {
    name: "CustomFunction",
    concrete: false,
    fields: {
      importPath: {
        name: "importPath",
        type: { type: "String", params: [] },
        annotations: [],
      },
      importName: {
        name: "importName",
        type: { type: "String", params: [] },
        annotations: [],
      },
      defaultExport: {
        name: "defaultExport",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      namespace: {
        name: "namespace",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  CodeLibrary: {
    name: "CodeLibrary",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      importPath: {
        name: "importPath",
        type: { type: "String", params: [] },
        annotations: [],
      },
      jsIdentifier: {
        name: "jsIdentifier",
        type: { type: "String", params: [] },
        annotations: [],
      },
      importType: {
        name: "importType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["default"] },
            { type: "StringLiteral", params: ["namespace"] },
            { type: "StringLiteral", params: ["named"] },
          ],
        },
        annotations: [],
      },
      namedImport: {
        name: "namedImport",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isSyntheticDefaultImport: {
        name: "isSyntheticDefaultImport",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  StyleNode: { name: "StyleNode", concrete: false, fields: {} },
  RuleSet: {
    name: "RuleSet",
    base: "StyleNode",
    concrete: false,
    fields: {
      values: {
        name: "values",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "String", params: [] },
          ],
        },
        annotations: [],
      },
      mixins: {
        name: "mixins",
        type: { type: "List", params: [{ type: "Mixin", params: [] }] },
        annotations: ["WeakRef"],
      },
    },
  },
  Rule: {
    name: "Rule",
    base: "StyleNode",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      values: {
        name: "values",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  VariantedRuleSet: {
    name: "VariantedRuleSet",
    concrete: false,
    fields: {
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: ["Const", "WeakRef"],
      },
      rs: {
        name: "rs",
        type: { type: "RuleSet", params: [] },
        annotations: [],
      },
    },
  },
  Mixin: {
    name: "Mixin",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      rs: {
        name: "rs",
        type: { type: "RuleSet", params: [] },
        annotations: [],
      },
      preview: {
        name: "preview",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      forTheme: {
        name: "forTheme",
        type: { type: "Bool", params: [] },
        annotations: ["Const"],
      },
      variantedRs: {
        name: "variantedRs",
        type: {
          type: "List",
          params: [{ type: "VariantedRuleSet", params: [] }],
        },
        annotations: [],
      },
    },
  },
  Theme: {
    name: "Theme",
    concrete: false,
    fields: {
      defaultStyle: {
        name: "defaultStyle",
        type: { type: "Mixin", params: [] },
        annotations: [],
      },
      styles: {
        name: "styles",
        type: { type: "List", params: [{ type: "ThemeStyle", params: [] }] },
        annotations: [],
      },
      layout: {
        name: "layout",
        type: {
          type: "Optional",
          params: [{ type: "ThemeLayoutSettings", params: [] }],
        },
        annotations: [],
      },
      addItemPrefs: {
        name: "addItemPrefs",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "RuleSet", params: [] },
          ],
        },
        annotations: [],
      },
      active: {
        name: "active",
        type: { type: "Bool", params: [] },
        annotations: ["Const"],
      },
    },
  },
  ThemeStyle: {
    name: "ThemeStyle",
    concrete: false,
    fields: {
      selector: {
        name: "selector",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      style: {
        name: "style",
        type: { type: "Mixin", params: [] },
        annotations: [],
      },
    },
  },
  ThemeLayoutSettings: {
    name: "ThemeLayoutSettings",
    concrete: false,
    fields: {
      rs: {
        name: "rs",
        type: { type: "RuleSet", params: [] },
        annotations: [],
      },
    },
  },
  ProjectDependency: {
    name: "ProjectDependency",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      pkgId: {
        name: "pkgId",
        type: { type: "String", params: [] },
        annotations: [],
      },
      projectId: {
        name: "projectId",
        type: { type: "String", params: [] },
        annotations: [],
      },
      version: {
        name: "version",
        type: { type: "String", params: [] },
        annotations: [],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      site: {
        name: "site",
        type: { type: "Site", params: [] },
        annotations: [],
      },
    },
  },
  ImageAsset: {
    name: "ImageAsset",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["picture"] },
            { type: "StringLiteral", params: ["icon"] },
          ],
        },
        annotations: ["Const"],
      },
      dataUri: {
        name: "dataUri",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      width: {
        name: "width",
        type: { type: "Optional", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
      height: {
        name: "height",
        type: { type: "Optional", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
      aspectRatio: {
        name: "aspectRatio",
        type: { type: "Optional", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
    },
  },
  TplNode: {
    name: "TplNode",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      parent: {
        name: "parent",
        type: { type: "Optional", params: [{ type: "TplNode", params: [] }] },
        annotations: ["WeakRef"],
      },
      locked: {
        name: "locked",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      vsettings: {
        name: "vsettings",
        type: {
          type: "List",
          params: [{ type: "VariantSetting", params: [] }],
        },
        annotations: [],
      },
    },
  },
  TplTag: {
    name: "TplTag",
    base: "TplNode",
    concrete: false,
    fields: {
      tag: {
        name: "tag",
        type: { type: "String", params: [] },
        annotations: [],
      },
      name: {
        name: "name",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      children: {
        name: "children",
        type: { type: "List", params: [{ type: "TplNode", params: [] }] },
        annotations: [],
      },
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["text"] },
            { type: "StringLiteral", params: ["image"] },
            { type: "StringLiteral", params: ["column"] },
            { type: "StringLiteral", params: ["columns"] },
            { type: "StringLiteral", params: ["other"] },
          ],
        },
        annotations: [],
      },
      codeGenType: {
        name: "codeGenType",
        type: {
          type: "Or",
          params: [
            {
              type: "Optional",
              params: [{ type: "StringLiteral", params: ["auto"] }],
            },
            {
              type: "Optional",
              params: [{ type: "StringLiteral", params: ["alwaysTag"] }],
            },
            {
              type: "Optional",
              params: [{ type: "StringLiteral", params: ["noTag"] }],
            },
          ],
        },
        annotations: [],
      },
      columnsSetting: {
        name: "columnsSetting",
        type: {
          type: "Optional",
          params: [{ type: "ColumnsSetting", params: [] }],
        },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      parent: {
        name: "parent",
        type: { type: "Optional", params: [{ type: "TplNode", params: [] }] },
        annotations: ["WeakRef"],
      },
      locked: {
        name: "locked",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      vsettings: {
        name: "vsettings",
        type: {
          type: "List",
          params: [{ type: "VariantSetting", params: [] }],
        },
        annotations: [],
      },
    },
  },
  TplComponent: {
    name: "TplComponent",
    base: "TplNode",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      component: {
        name: "component",
        type: { type: "Component", params: [] },
        annotations: ["WeakRef"],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      parent: {
        name: "parent",
        type: { type: "Optional", params: [{ type: "TplNode", params: [] }] },
        annotations: ["WeakRef"],
      },
      locked: {
        name: "locked",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      vsettings: {
        name: "vsettings",
        type: {
          type: "List",
          params: [{ type: "VariantSetting", params: [] }],
        },
        annotations: [],
      },
    },
  },
  TplSlot: {
    name: "TplSlot",
    base: "TplNode",
    concrete: false,
    fields: {
      param: {
        name: "param",
        type: { type: "SlotParam", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      defaultContents: {
        name: "defaultContents",
        type: { type: "List", params: [{ type: "TplNode", params: [] }] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      parent: {
        name: "parent",
        type: { type: "Optional", params: [{ type: "TplNode", params: [] }] },
        annotations: ["WeakRef"],
      },
      locked: {
        name: "locked",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      vsettings: {
        name: "vsettings",
        type: {
          type: "List",
          params: [{ type: "VariantSetting", params: [] }],
        },
        annotations: [],
      },
    },
  },
  ColumnsSetting: {
    name: "ColumnsSetting",
    concrete: false,
    fields: {
      screenBreakpoint: {
        name: "screenBreakpoint",
        type: { type: "Optional", params: [{ type: "Variant", params: [] }] },
        annotations: ["WeakRef"],
      },
    },
  },
  PageMeta: {
    name: "PageMeta",
    concrete: false,
    fields: {
      path: {
        name: "path",
        type: { type: "String", params: [] },
        annotations: [],
      },
      params: {
        name: "params",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "String", params: [] },
          ],
        },
        annotations: [],
      },
      query: {
        name: "query",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "String", params: [] },
          ],
        },
        annotations: [],
      },
      title: {
        name: "title",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "String", params: [] },
        annotations: [],
      },
      canonical: {
        name: "canonical",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      roleId: {
        name: "roleId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      openGraphImage: {
        name: "openGraphImage",
        type: {
          type: "Or",
          params: [
            { type: "Optional", params: [{ type: "ImageAsset", params: [] }] },
            { type: "Optional", params: [{ type: "String", params: [] }] },
          ],
        },
        annotations: ["WeakRef"],
      },
    },
  },
  ComponentDataQuery: {
    name: "ComponentDataQuery",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      op: {
        name: "op",
        type: {
          type: "Optional",
          params: [{ type: "DataSourceOpExpr", params: [] }],
        },
        annotations: [],
      },
    },
  },
  CodeComponentHelper: {
    name: "CodeComponentHelper",
    concrete: false,
    fields: {
      importPath: {
        name: "importPath",
        type: { type: "String", params: [] },
        annotations: [],
      },
      importName: {
        name: "importName",
        type: { type: "String", params: [] },
        annotations: [],
      },
      defaultExport: {
        name: "defaultExport",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  CodeComponentVariantMeta: {
    name: "CodeComponentVariantMeta",
    concrete: false,
    fields: {
      cssSelector: {
        name: "cssSelector",
        type: { type: "String", params: [] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "String", params: [] },
        annotations: [],
      },
    },
  },
  CodeComponentMeta: {
    name: "CodeComponentMeta",
    concrete: false,
    fields: {
      importPath: {
        name: "importPath",
        type: { type: "String", params: [] },
        annotations: [],
      },
      defaultExport: {
        name: "defaultExport",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      importName: {
        name: "importName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      section: {
        name: "section",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      thumbnailUrl: {
        name: "thumbnailUrl",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      classNameProp: {
        name: "classNameProp",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      refProp: {
        name: "refProp",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      defaultStyles: {
        name: "defaultStyles",
        type: { type: "Optional", params: [{ type: "RuleSet", params: [] }] },
        annotations: [],
      },
      defaultDisplay: {
        name: "defaultDisplay",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isHostLess: {
        name: "isHostLess",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isContext: {
        name: "isContext",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isAttachment: {
        name: "isAttachment",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      providesData: {
        name: "providesData",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      hasRef: {
        name: "hasRef",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isRepeatable: {
        name: "isRepeatable",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      styleSections: {
        name: "styleSections",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      helpers: {
        name: "helpers",
        type: {
          type: "Optional",
          params: [{ type: "CodeComponentHelper", params: [] }],
        },
        annotations: [],
      },
      defaultSlotContents: {
        name: "defaultSlotContents",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "Any", params: [] },
          ],
        },
        annotations: [],
      },
      variants: {
        name: "variants",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "CodeComponentVariantMeta", params: [] },
          ],
        },
        annotations: [],
      },
    },
  },
  Component: {
    name: "Component",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      params: {
        name: "params",
        type: { type: "Set", params: [{ type: "Param", params: [] }] },
        annotations: [],
      },
      states: {
        name: "states",
        type: { type: "Set", params: [{ type: "State", params: [] }] },
        annotations: [],
      },
      tplTree: {
        name: "tplTree",
        type: { type: "TplNode", params: [] },
        annotations: [],
      },
      editableByContentEditor: {
        name: "editableByContentEditor",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      hiddenFromContentEditor: {
        name: "hiddenFromContentEditor",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: [],
      },
      variantGroups: {
        name: "variantGroups",
        type: {
          type: "List",
          params: [{ type: "ComponentVariantGroup", params: [] }],
        },
        annotations: [],
      },
      pageMeta: {
        name: "pageMeta",
        type: { type: "Optional", params: [{ type: "PageMeta", params: [] }] },
        annotations: [],
      },
      codeComponentMeta: {
        name: "codeComponentMeta",
        type: {
          type: "Optional",
          params: [{ type: "CodeComponentMeta", params: [] }],
        },
        annotations: [],
      },
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["plain"] },
            { type: "StringLiteral", params: ["page"] },
            { type: "StringLiteral", params: ["code"] },
            { type: "StringLiteral", params: ["frame"] },
          ],
        },
        annotations: [],
      },
      subComps: {
        name: "subComps",
        type: { type: "List", params: [{ type: "Component", params: [] }] },
        annotations: ["WeakRef"],
      },
      superComp: {
        name: "superComp",
        type: { type: "Optional", params: [{ type: "Component", params: [] }] },
        annotations: ["WeakRef"],
      },
      plumeInfo: {
        name: "plumeInfo",
        type: { type: "Optional", params: [{ type: "PlumeInfo", params: [] }] },
        annotations: ["Const"],
      },
      templateInfo: {
        name: "templateInfo",
        type: {
          type: "Optional",
          params: [{ type: "ComponentTemplateInfo", params: [] }],
        },
        annotations: [],
      },
      metadata: {
        name: "metadata",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "String", params: [] },
          ],
        },
        annotations: [],
      },
      dataQueries: {
        name: "dataQueries",
        type: {
          type: "List",
          params: [{ type: "ComponentDataQuery", params: [] }],
        },
        annotations: [],
      },
      figmaMappings: {
        name: "figmaMappings",
        type: {
          type: "List",
          params: [{ type: "FigmaComponentMapping", params: [] }],
        },
        annotations: [],
      },
      alwaysAutoName: {
        name: "alwaysAutoName",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      trapsFocus: {
        name: "trapsFocus",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  NameArg: {
    name: "NameArg",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      expr: {
        name: "expr",
        type: { type: "Expr", params: [] },
        annotations: [],
      },
    },
  },
  PlumeInfo: {
    name: "PlumeInfo",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: { type: "String", params: [] },
        annotations: [],
      },
    },
  },
  ComponentTemplateInfo: {
    name: "ComponentTemplateInfo",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      projectId: {
        name: "projectId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      componentId: {
        name: "componentId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  Variant: {
    name: "Variant",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      selectors: {
        name: "selectors",
        type: {
          type: "Optional",
          params: [{ type: "List", params: [{ type: "String", params: [] }] }],
        },
        annotations: [],
      },
      codeComponentName: {
        name: "codeComponentName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      codeComponentVariantKeys: {
        name: "codeComponentVariantKeys",
        type: {
          type: "Optional",
          params: [{ type: "List", params: [{ type: "String", params: [] }] }],
        },
        annotations: [],
      },
      parent: {
        name: "parent",
        type: {
          type: "Optional",
          params: [{ type: "VariantGroup", params: [] }],
        },
        annotations: ["WeakRef"],
      },
      mediaQuery: {
        name: "mediaQuery",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      forTpl: {
        name: "forTpl",
        type: {
          type: "Or",
          params: [
            { type: "Optional", params: [{ type: "TplTag", params: [] }] },
            {
              type: "Optional",
              params: [{ type: "TplComponent", params: [] }],
            },
            { type: "Optional", params: [{ type: "TplSlot", params: [] }] },
          ],
        },
        annotations: ["WeakRef"],
      },
    },
  },
  VariantGroup: {
    name: "VariantGroup",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: [],
      },
      multi: {
        name: "multi",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  GlobalVariantGroup: {
    name: "GlobalVariantGroup",
    base: "VariantGroup",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["global-screen"] },
            { type: "StringLiteral", params: ["global-user-defined"] },
          ],
        },
        annotations: [],
      },
      param: {
        name: "param",
        type: { type: "GlobalVariantGroupParam", params: [] },
        annotations: ["Const"],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: [],
      },
      multi: {
        name: "multi",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  ComponentVariantGroup: {
    name: "ComponentVariantGroup",
    base: "VariantGroup",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: { type: "StringLiteral", params: ["component"] },
        annotations: [],
      },
      param: {
        name: "param",
        type: { type: "StateParam", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      linkedState: {
        name: "linkedState",
        type: { type: "VariantGroupState", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: [],
      },
      multi: {
        name: "multi",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  VariantSetting: {
    name: "VariantSetting",
    concrete: false,
    fields: {
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: ["WeakRef"],
      },
      args: {
        name: "args",
        type: { type: "List", params: [{ type: "Arg", params: [] }] },
        annotations: [],
      },
      attrs: {
        name: "attrs",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "Expr", params: [] },
          ],
        },
        annotations: [],
      },
      rs: {
        name: "rs",
        type: { type: "RuleSet", params: [] },
        annotations: [],
      },
      dataCond: {
        name: "dataCond",
        type: {
          type: "Or",
          params: [
            { type: "Optional", params: [{ type: "CustomCode", params: [] }] },
            { type: "Optional", params: [{ type: "ObjectPath", params: [] }] },
          ],
        },
        annotations: [],
      },
      dataRep: {
        name: "dataRep",
        type: { type: "Optional", params: [{ type: "Rep", params: [] }] },
        annotations: [],
      },
      text: {
        name: "text",
        type: { type: "Optional", params: [{ type: "RichText", params: [] }] },
        annotations: [],
      },
      columnsConfig: {
        name: "columnsConfig",
        type: {
          type: "Optional",
          params: [{ type: "ColumnsConfig", params: [] }],
        },
        annotations: [],
      },
    },
  },
  Interaction: {
    name: "Interaction",
    concrete: false,
    fields: {
      interactionName: {
        name: "interactionName",
        type: { type: "String", params: [] },
        annotations: [],
      },
      actionName: {
        name: "actionName",
        type: { type: "String", params: [] },
        annotations: [],
      },
      args: {
        name: "args",
        type: { type: "List", params: [{ type: "NameArg", params: [] }] },
        annotations: [],
      },
      condExpr: {
        name: "condExpr",
        type: {
          type: "Or",
          params: [
            { type: "Optional", params: [{ type: "CustomCode", params: [] }] },
            { type: "Optional", params: [{ type: "ObjectPath", params: [] }] },
          ],
        },
        annotations: [],
      },
      conditionalMode: {
        name: "conditionalMode",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["always"] },
            { type: "StringLiteral", params: ["never"] },
            { type: "StringLiteral", params: ["expression"] },
          ],
        },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      parent: {
        name: "parent",
        type: { type: "EventHandler", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  ColumnsConfig: {
    name: "ColumnsConfig",
    concrete: false,
    fields: {
      breakUpRows: {
        name: "breakUpRows",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      colsSizes: {
        name: "colsSizes",
        type: { type: "List", params: [{ type: "Number", params: [] }] },
        annotations: [],
      },
    },
  },
  Marker: {
    name: "Marker",
    concrete: false,
    fields: {
      position: {
        name: "position",
        type: { type: "Number", params: [] },
        annotations: [],
      },
      length: {
        name: "length",
        type: { type: "Number", params: [] },
        annotations: [],
      },
    },
  },
  StyleMarker: {
    name: "StyleMarker",
    base: "Marker",
    concrete: false,
    fields: {
      rs: {
        name: "rs",
        type: { type: "RuleSet", params: [] },
        annotations: [],
      },
      position: {
        name: "position",
        type: { type: "Number", params: [] },
        annotations: [],
      },
      length: {
        name: "length",
        type: { type: "Number", params: [] },
        annotations: [],
      },
    },
  },
  NodeMarker: {
    name: "NodeMarker",
    base: "Marker",
    concrete: false,
    fields: {
      tpl: {
        name: "tpl",
        type: { type: "TplNode", params: [] },
        annotations: ["WeakRef"],
      },
      position: {
        name: "position",
        type: { type: "Number", params: [] },
        annotations: [],
      },
      length: {
        name: "length",
        type: { type: "Number", params: [] },
        annotations: [],
      },
    },
  },
  RichText: { name: "RichText", concrete: false, fields: {} },
  RawText: {
    name: "RawText",
    base: "RichText",
    concrete: false,
    fields: {
      markers: {
        name: "markers",
        type: { type: "List", params: [{ type: "Marker", params: [] }] },
        annotations: [],
      },
      text: {
        name: "text",
        type: { type: "String", params: [] },
        annotations: [],
      },
    },
  },
  ExprText: {
    name: "ExprText",
    base: "RichText",
    concrete: false,
    fields: {
      expr: {
        name: "expr",
        type: {
          type: "Or",
          params: [
            { type: "CustomCode", params: [] },
            { type: "ObjectPath", params: [] },
          ],
        },
        annotations: [],
      },
      html: {
        name: "html",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  Var: {
    name: "Var",
    concrete: false,
    fields: {
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
    },
  },
  BindingStruct: { name: "BindingStruct", concrete: false, fields: {} },
  Rep: {
    name: "Rep",
    base: "BindingStruct",
    concrete: false,
    fields: {
      element: {
        name: "element",
        type: { type: "Var", params: [] },
        annotations: [],
      },
      index: {
        name: "index",
        type: { type: "Optional", params: [{ type: "Var", params: [] }] },
        annotations: [],
      },
      collection: {
        name: "collection",
        type: {
          type: "Or",
          params: [
            { type: "CustomCode", params: [] },
            { type: "ObjectPath", params: [] },
          ],
        },
        annotations: [],
      },
    },
  },
  Param: {
    name: "Param",
    base: "BindingStruct",
    concrete: false,
    fields: {
      variable: {
        name: "variable",
        type: { type: "Var", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      enumValues: {
        name: "enumValues",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Bool", params: [] },
                { type: "Number", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      origin: {
        name: "origin",
        type: {
          type: "Or",
          params: [
            { type: "String", params: [] },
            { type: "Optional", params: [{ type: "Number", params: [] }] },
          ],
        },
        annotations: [],
      },
      exportType: {
        name: "exportType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["Internal"] },
            { type: "StringLiteral", params: ["External"] },
            { type: "StringLiteral", params: ["ToolsOnly"] },
          ],
        },
        annotations: [],
      },
      defaultExpr: {
        name: "defaultExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      previewExpr: {
        name: "previewExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      propEffect: {
        name: "propEffect",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      about: {
        name: "about",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isRepeated: {
        name: "isRepeated",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      isMainContentSlot: {
        name: "isMainContentSlot",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      required: {
        name: "required",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      mergeWithParent: {
        name: "mergeWithParent",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isLocalizable: {
        name: "isLocalizable",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  SlotParam: {
    name: "SlotParam",
    base: "Param",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "RenderableType", params: [] },
            { type: "RenderFuncType", params: [] },
          ],
        },
        annotations: [],
      },
      tplSlot: {
        name: "tplSlot",
        type: { type: "TplSlot", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      variable: {
        name: "variable",
        type: { type: "Var", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      enumValues: {
        name: "enumValues",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Bool", params: [] },
                { type: "Number", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      origin: {
        name: "origin",
        type: {
          type: "Or",
          params: [
            { type: "String", params: [] },
            { type: "Optional", params: [{ type: "Number", params: [] }] },
          ],
        },
        annotations: [],
      },
      exportType: {
        name: "exportType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["Internal"] },
            { type: "StringLiteral", params: ["External"] },
            { type: "StringLiteral", params: ["ToolsOnly"] },
          ],
        },
        annotations: [],
      },
      defaultExpr: {
        name: "defaultExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      previewExpr: {
        name: "previewExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      propEffect: {
        name: "propEffect",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      about: {
        name: "about",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isRepeated: {
        name: "isRepeated",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      isMainContentSlot: {
        name: "isMainContentSlot",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      required: {
        name: "required",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      mergeWithParent: {
        name: "mergeWithParent",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isLocalizable: {
        name: "isLocalizable",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  StateParam: {
    name: "StateParam",
    base: "Param",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "PrimitiveType", params: [] },
            { type: "Img", params: [] },
            { type: "HrefType", params: [] },
            { type: "TargetType", params: [] },
            { type: "DateString", params: [] },
            { type: "DateRangeStrings", params: [] },
            { type: "QueryData", params: [] },
            { type: "StylePropType", params: [] },
          ],
        },
        annotations: [],
      },
      state: {
        name: "state",
        type: { type: "State", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      variable: {
        name: "variable",
        type: { type: "Var", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      enumValues: {
        name: "enumValues",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Bool", params: [] },
                { type: "Number", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      origin: {
        name: "origin",
        type: {
          type: "Or",
          params: [
            { type: "String", params: [] },
            { type: "Optional", params: [{ type: "Number", params: [] }] },
          ],
        },
        annotations: [],
      },
      exportType: {
        name: "exportType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["Internal"] },
            { type: "StringLiteral", params: ["External"] },
            { type: "StringLiteral", params: ["ToolsOnly"] },
          ],
        },
        annotations: [],
      },
      defaultExpr: {
        name: "defaultExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      previewExpr: {
        name: "previewExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      propEffect: {
        name: "propEffect",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      about: {
        name: "about",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isRepeated: {
        name: "isRepeated",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      isMainContentSlot: {
        name: "isMainContentSlot",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      required: {
        name: "required",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      mergeWithParent: {
        name: "mergeWithParent",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isLocalizable: {
        name: "isLocalizable",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  GlobalVariantGroupParam: {
    name: "GlobalVariantGroupParam",
    base: "Param",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "Text", params: [] },
            { type: "BoolType", params: [] },
            { type: "Choice", params: [] },
            { type: "AnyType", params: [] },
          ],
        },
        annotations: [],
      },
      variable: {
        name: "variable",
        type: { type: "Var", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      enumValues: {
        name: "enumValues",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Bool", params: [] },
                { type: "Number", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      origin: {
        name: "origin",
        type: {
          type: "Or",
          params: [
            { type: "String", params: [] },
            { type: "Optional", params: [{ type: "Number", params: [] }] },
          ],
        },
        annotations: [],
      },
      exportType: {
        name: "exportType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["Internal"] },
            { type: "StringLiteral", params: ["External"] },
            { type: "StringLiteral", params: ["ToolsOnly"] },
          ],
        },
        annotations: [],
      },
      defaultExpr: {
        name: "defaultExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      previewExpr: {
        name: "previewExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      propEffect: {
        name: "propEffect",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      about: {
        name: "about",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isRepeated: {
        name: "isRepeated",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      isMainContentSlot: {
        name: "isMainContentSlot",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      required: {
        name: "required",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      mergeWithParent: {
        name: "mergeWithParent",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isLocalizable: {
        name: "isLocalizable",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  StateChangeHandlerParam: {
    name: "StateChangeHandlerParam",
    base: "Param",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: { type: "FunctionType", params: [] },
        annotations: [],
      },
      state: {
        name: "state",
        type: { type: "State", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      variable: {
        name: "variable",
        type: { type: "Var", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      enumValues: {
        name: "enumValues",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Bool", params: [] },
                { type: "Number", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      origin: {
        name: "origin",
        type: {
          type: "Or",
          params: [
            { type: "String", params: [] },
            { type: "Optional", params: [{ type: "Number", params: [] }] },
          ],
        },
        annotations: [],
      },
      exportType: {
        name: "exportType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["Internal"] },
            { type: "StringLiteral", params: ["External"] },
            { type: "StringLiteral", params: ["ToolsOnly"] },
          ],
        },
        annotations: [],
      },
      defaultExpr: {
        name: "defaultExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      previewExpr: {
        name: "previewExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      propEffect: {
        name: "propEffect",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      about: {
        name: "about",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isRepeated: {
        name: "isRepeated",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      isMainContentSlot: {
        name: "isMainContentSlot",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      required: {
        name: "required",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      mergeWithParent: {
        name: "mergeWithParent",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isLocalizable: {
        name: "isLocalizable",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  PropParam: {
    name: "PropParam",
    base: "Param",
    concrete: false,
    fields: {
      type: {
        name: "type",
        type: {
          type: "Or",
          params: [
            { type: "PrimitiveType", params: [] },
            { type: "Img", params: [] },
            { type: "HrefType", params: [] },
            { type: "TargetType", params: [] },
            { type: "DateString", params: [] },
            { type: "DateRangeStrings", params: [] },
            { type: "QueryData", params: [] },
            { type: "FunctionType", params: [] },
            { type: "StylePropType", params: [] },
          ],
        },
        annotations: [],
      },
      variable: {
        name: "variable",
        type: { type: "Var", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      enumValues: {
        name: "enumValues",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Bool", params: [] },
                { type: "Number", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      origin: {
        name: "origin",
        type: {
          type: "Or",
          params: [
            { type: "String", params: [] },
            { type: "Optional", params: [{ type: "Number", params: [] }] },
          ],
        },
        annotations: [],
      },
      exportType: {
        name: "exportType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["Internal"] },
            { type: "StringLiteral", params: ["External"] },
            { type: "StringLiteral", params: ["ToolsOnly"] },
          ],
        },
        annotations: [],
      },
      defaultExpr: {
        name: "defaultExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      previewExpr: {
        name: "previewExpr",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
      propEffect: {
        name: "propEffect",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      displayName: {
        name: "displayName",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      about: {
        name: "about",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      isRepeated: {
        name: "isRepeated",
        type: { type: "Optional", params: [{ type: "Bool", params: [] }] },
        annotations: [],
      },
      isMainContentSlot: {
        name: "isMainContentSlot",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      required: {
        name: "required",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      mergeWithParent: {
        name: "mergeWithParent",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
      isLocalizable: {
        name: "isLocalizable",
        type: { type: "Bool", params: [] },
        annotations: [],
      },
    },
  },
  Arg: {
    name: "Arg",
    base: "BindingStruct",
    concrete: false,
    fields: {
      param: {
        name: "param",
        type: { type: "Param", params: [] },
        annotations: ["WeakRef"],
      },
      expr: {
        name: "expr",
        type: { type: "Expr", params: [] },
        annotations: [],
      },
    },
  },
  Expr: { name: "Expr", concrete: false, fields: {} },
  RenderExpr: {
    name: "RenderExpr",
    base: "Expr",
    concrete: true,
    fields: {
      tpl: {
        name: "tpl",
        type: { type: "List", params: [{ type: "TplNode", params: [] }] },
        annotations: [],
      },
    },
  },
  VirtualRenderExpr: {
    name: "VirtualRenderExpr",
    base: "RenderExpr",
    concrete: false,
    fields: {
      tpl: {
        name: "tpl",
        type: { type: "List", params: [{ type: "TplNode", params: [] }] },
        annotations: [],
      },
    },
  },
  CustomCode: {
    name: "CustomCode",
    base: "Expr",
    concrete: false,
    fields: {
      code: {
        name: "code",
        type: { type: "String", params: [] },
        annotations: [],
      },
      fallback: {
        name: "fallback",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
    },
  },
  DataSourceOpExpr: {
    name: "DataSourceOpExpr",
    base: "Expr",
    concrete: false,
    fields: {
      parent: {
        name: "parent",
        type: { type: "Optional", params: [{ type: "QueryRef", params: [] }] },
        annotations: [],
      },
      sourceId: {
        name: "sourceId",
        type: { type: "String", params: [] },
        annotations: [],
      },
      opId: {
        name: "opId",
        type: { type: "String", params: [] },
        annotations: [],
      },
      opName: {
        name: "opName",
        type: { type: "String", params: [] },
        annotations: [],
      },
      templates: {
        name: "templates",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "DataSourceTemplate", params: [] },
          ],
        },
        annotations: [],
      },
      cacheKey: {
        name: "cacheKey",
        type: {
          type: "Optional",
          params: [{ type: "TemplatedString", params: [] }],
        },
        annotations: [],
      },
      queryInvalidation: {
        name: "queryInvalidation",
        type: {
          type: "Optional",
          params: [{ type: "QueryInvalidationExpr", params: [] }],
        },
        annotations: [],
      },
      roleId: {
        name: "roleId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  VarRef: {
    name: "VarRef",
    base: "Expr",
    concrete: false,
    fields: {
      variable: {
        name: "variable",
        type: { type: "Var", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  TplRef: {
    name: "TplRef",
    base: "Expr",
    concrete: false,
    fields: {
      tpl: {
        name: "tpl",
        type: { type: "TplNode", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  StyleTokenRef: {
    name: "StyleTokenRef",
    base: "Expr",
    concrete: false,
    fields: {
      token: {
        name: "token",
        type: { type: "StyleToken", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  ImageAssetRef: {
    name: "ImageAssetRef",
    base: "Expr",
    concrete: false,
    fields: {
      asset: {
        name: "asset",
        type: { type: "ImageAsset", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  PageHref: {
    name: "PageHref",
    base: "Expr",
    concrete: false,
    fields: {
      page: {
        name: "page",
        type: { type: "Component", params: [] },
        annotations: ["WeakRef"],
      },
      params: {
        name: "params",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            {
              type: "Or",
              params: [
                { type: "TemplatedString", params: [] },
                { type: "CustomCode", params: [] },
                { type: "ObjectPath", params: [] },
                { type: "VarRef", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
    },
  },
  VariantsRef: {
    name: "VariantsRef",
    base: "Expr",
    concrete: false,
    fields: {
      variants: {
        name: "variants",
        type: { type: "List", params: [{ type: "Variant", params: [] }] },
        annotations: ["WeakRef"],
      },
    },
  },
  ObjectPath: {
    name: "ObjectPath",
    base: "Expr",
    concrete: false,
    fields: {
      path: {
        name: "path",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "Number", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      fallback: {
        name: "fallback",
        type: { type: "Optional", params: [{ type: "Expr", params: [] }] },
        annotations: [],
      },
    },
  },
  EventHandler: {
    name: "EventHandler",
    base: "Expr",
    concrete: true,
    fields: {
      interactions: {
        name: "interactions",
        type: { type: "List", params: [{ type: "Interaction", params: [] }] },
        annotations: [],
      },
    },
  },
  GenericEventHandler: {
    name: "GenericEventHandler",
    base: "EventHandler",
    concrete: false,
    fields: {
      handlerType: {
        name: "handlerType",
        type: { type: "FunctionType", params: [] },
        annotations: [],
      },
      interactions: {
        name: "interactions",
        type: { type: "List", params: [{ type: "Interaction", params: [] }] },
        annotations: [],
      },
    },
  },
  FunctionArg: {
    name: "FunctionArg",
    base: "Expr",
    concrete: true,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      argType: {
        name: "argType",
        type: { type: "ArgType", params: [] },
        annotations: ["WeakRef"],
      },
      expr: {
        name: "expr",
        type: { type: "Expr", params: [] },
        annotations: [],
      },
    },
  },
  StrongFunctionArg: {
    name: "StrongFunctionArg",
    base: "FunctionArg",
    concrete: false,
    fields: {
      argType: {
        name: "argType",
        type: { type: "ArgType", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      expr: {
        name: "expr",
        type: { type: "Expr", params: [] },
        annotations: [],
      },
    },
  },
  CollectionExpr: {
    name: "CollectionExpr",
    base: "Expr",
    concrete: false,
    fields: {
      exprs: {
        name: "exprs",
        type: {
          type: "List",
          params: [
            { type: "Optional", params: [{ type: "Expr", params: [] }] },
          ],
        },
        annotations: [],
      },
    },
  },
  MapExpr: {
    name: "MapExpr",
    base: "Expr",
    concrete: false,
    fields: {
      mapExpr: {
        name: "mapExpr",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "Expr", params: [] },
          ],
        },
        annotations: [],
      },
    },
  },
  StyleExpr: {
    name: "StyleExpr",
    base: "Expr",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: [],
      },
      styles: {
        name: "styles",
        type: {
          type: "List",
          params: [{ type: "SelectorRuleSet", params: [] }],
        },
        annotations: [],
      },
    },
  },
  TemplatedString: {
    name: "TemplatedString",
    base: "Expr",
    concrete: false,
    fields: {
      text: {
        name: "text",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "String", params: [] },
                { type: "ObjectPath", params: [] },
                { type: "CustomCode", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
    },
  },
  FunctionExpr: {
    name: "FunctionExpr",
    base: "Expr",
    concrete: false,
    fields: {
      argNames: {
        name: "argNames",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      bodyExpr: {
        name: "bodyExpr",
        type: { type: "Expr", params: [] },
        annotations: [],
      },
    },
  },
  QueryInvalidationExpr: {
    name: "QueryInvalidationExpr",
    base: "Expr",
    concrete: false,
    fields: {
      invalidationQueries: {
        name: "invalidationQueries",
        type: {
          type: "List",
          params: [
            {
              type: "Or",
              params: [
                { type: "QueryRef", params: [] },
                { type: "String", params: [] },
              ],
            },
          ],
        },
        annotations: [],
      },
      invalidationKeys: {
        name: "invalidationKeys",
        type: {
          type: "Or",
          params: [
            { type: "Optional", params: [{ type: "CustomCode", params: [] }] },
            { type: "Optional", params: [{ type: "ObjectPath", params: [] }] },
          ],
        },
        annotations: [],
      },
    },
  },
  CompositeExpr: {
    name: "CompositeExpr",
    base: "Expr",
    concrete: false,
    fields: {
      hostLiteral: {
        name: "hostLiteral",
        type: { type: "String", params: [] },
        annotations: [],
      },
      substitutions: {
        name: "substitutions",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "Expr", params: [] },
          ],
        },
        annotations: [],
      },
    },
  },
  SelectorRuleSet: {
    name: "SelectorRuleSet",
    concrete: false,
    fields: {
      rs: {
        name: "rs",
        type: { type: "RuleSet", params: [] },
        annotations: [],
      },
      selector: {
        name: "selector",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  LabeledSelector: {
    name: "LabeledSelector",
    concrete: false,
    fields: {
      selector: {
        name: "selector",
        type: { type: "String", params: [] },
        annotations: [],
      },
      label: {
        name: "label",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      defaultStyles: {
        name: "defaultStyles",
        type: {
          type: "Map",
          params: [
            { type: "String", params: [] },
            { type: "String", params: [] },
          ],
        },
        annotations: [],
      },
    },
  },
  DataSourceTemplate: {
    name: "DataSourceTemplate",
    concrete: false,
    fields: {
      fieldType: {
        name: "fieldType",
        type: { type: "String", params: [] },
        annotations: [],
      },
      value: {
        name: "value",
        type: {
          type: "Or",
          params: [
            { type: "TemplatedString", params: [] },
            { type: "String", params: [] },
          ],
        },
        annotations: [],
      },
      bindings: {
        name: "bindings",
        type: {
          type: "Optional",
          params: [
            {
              type: "Map",
              params: [
                { type: "String", params: [] },
                {
                  type: "Or",
                  params: [
                    { type: "TemplatedString", params: [] },
                    { type: "CustomCode", params: [] },
                    { type: "ObjectPath", params: [] },
                  ],
                },
              ],
            },
          ],
        },
        annotations: [],
      },
    },
  },
  QueryRef: {
    name: "QueryRef",
    concrete: false,
    fields: {
      ref: {
        name: "ref",
        type: {
          type: "Or",
          params: [
            { type: "TplNode", params: [] },
            { type: "ComponentDataQuery", params: [] },
          ],
        },
        annotations: ["WeakRef"],
      },
    },
  },
  State: {
    name: "State",
    concrete: true,
    fields: {
      param: {
        name: "param",
        type: { type: "StateParam", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      accessType: {
        name: "accessType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["private"] },
            { type: "StringLiteral", params: ["readonly"] },
            { type: "StringLiteral", params: ["writable"] },
          ],
        },
        annotations: [],
      },
      variableType: {
        name: "variableType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["text"] },
            { type: "StringLiteral", params: ["number"] },
            { type: "StringLiteral", params: ["boolean"] },
            { type: "StringLiteral", params: ["array"] },
            { type: "StringLiteral", params: ["object"] },
            { type: "StringLiteral", params: ["variant"] },
            { type: "StringLiteral", params: ["dateString"] },
            { type: "StringLiteral", params: ["dateRangeStrings"] },
          ],
        },
        annotations: [],
      },
      onChangeParam: {
        name: "onChangeParam",
        type: {
          type: "Or",
          params: [
            { type: "StateChangeHandlerParam", params: [] },
            { type: "PropParam", params: [] },
          ],
        },
        annotations: ["Const", "WeakRef"],
      },
      tplNode: {
        name: "tplNode",
        type: {
          type: "Or",
          params: [
            {
              type: "Optional",
              params: [{ type: "TplComponent", params: [] }],
            },
            { type: "Optional", params: [{ type: "TplTag", params: [] }] },
          ],
        },
        annotations: ["WeakRef"],
      },
      implicitState: {
        name: "implicitState",
        type: { type: "Optional", params: [{ type: "State", params: [] }] },
        annotations: ["WeakRef"],
      },
    },
  },
  NamedState: {
    name: "NamedState",
    base: "State",
    concrete: false,
    fields: {
      variableType: {
        name: "variableType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["text"] },
            { type: "StringLiteral", params: ["number"] },
            { type: "StringLiteral", params: ["boolean"] },
            { type: "StringLiteral", params: ["array"] },
            { type: "StringLiteral", params: ["object"] },
            { type: "StringLiteral", params: ["dateString"] },
            { type: "StringLiteral", params: ["dateRangeStrings"] },
          ],
        },
        annotations: [],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      param: {
        name: "param",
        type: { type: "StateParam", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      accessType: {
        name: "accessType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["private"] },
            { type: "StringLiteral", params: ["readonly"] },
            { type: "StringLiteral", params: ["writable"] },
          ],
        },
        annotations: [],
      },
      onChangeParam: {
        name: "onChangeParam",
        type: {
          type: "Or",
          params: [
            { type: "StateChangeHandlerParam", params: [] },
            { type: "PropParam", params: [] },
          ],
        },
        annotations: ["Const", "WeakRef"],
      },
      tplNode: {
        name: "tplNode",
        type: {
          type: "Or",
          params: [
            {
              type: "Optional",
              params: [{ type: "TplComponent", params: [] }],
            },
            { type: "Optional", params: [{ type: "TplTag", params: [] }] },
          ],
        },
        annotations: ["WeakRef"],
      },
      implicitState: {
        name: "implicitState",
        type: { type: "Optional", params: [{ type: "State", params: [] }] },
        annotations: ["WeakRef"],
      },
    },
  },
  VariantGroupState: {
    name: "VariantGroupState",
    base: "State",
    concrete: false,
    fields: {
      variantGroup: {
        name: "variantGroup",
        type: { type: "ComponentVariantGroup", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      param: {
        name: "param",
        type: { type: "StateParam", params: [] },
        annotations: ["Const", "WeakRef"],
      },
      accessType: {
        name: "accessType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["private"] },
            { type: "StringLiteral", params: ["readonly"] },
            { type: "StringLiteral", params: ["writable"] },
          ],
        },
        annotations: [],
      },
      variableType: {
        name: "variableType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["text"] },
            { type: "StringLiteral", params: ["number"] },
            { type: "StringLiteral", params: ["boolean"] },
            { type: "StringLiteral", params: ["array"] },
            { type: "StringLiteral", params: ["object"] },
            { type: "StringLiteral", params: ["variant"] },
            { type: "StringLiteral", params: ["dateString"] },
            { type: "StringLiteral", params: ["dateRangeStrings"] },
          ],
        },
        annotations: [],
      },
      onChangeParam: {
        name: "onChangeParam",
        type: {
          type: "Or",
          params: [
            { type: "StateChangeHandlerParam", params: [] },
            { type: "PropParam", params: [] },
          ],
        },
        annotations: ["Const", "WeakRef"],
      },
      tplNode: {
        name: "tplNode",
        type: {
          type: "Or",
          params: [
            {
              type: "Optional",
              params: [{ type: "TplComponent", params: [] }],
            },
            { type: "Optional", params: [{ type: "TplTag", params: [] }] },
          ],
        },
        annotations: ["WeakRef"],
      },
      implicitState: {
        name: "implicitState",
        type: { type: "Optional", params: [{ type: "State", params: [] }] },
        annotations: ["WeakRef"],
      },
    },
  },
  Split: {
    name: "Split",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      splitType: {
        name: "splitType",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["experiment"] },
            { type: "StringLiteral", params: ["segment"] },
            { type: "StringLiteral", params: ["schedule"] },
          ],
        },
        annotations: [],
      },
      slices: {
        name: "slices",
        type: { type: "List", params: [{ type: "SplitSlice", params: [] }] },
        annotations: [],
      },
      status: {
        name: "status",
        type: {
          type: "Or",
          params: [
            { type: "StringLiteral", params: ["new"] },
            { type: "StringLiteral", params: ["running"] },
            { type: "StringLiteral", params: ["stopped"] },
          ],
        },
        annotations: [],
      },
      targetEvents: {
        name: "targetEvents",
        type: { type: "List", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      description: {
        name: "description",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      externalId: {
        name: "externalId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
    },
  },
  SplitSlice: {
    name: "SplitSlice",
    concrete: false,
    fields: {
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      externalId: {
        name: "externalId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      contents: {
        name: "contents",
        type: { type: "List", params: [{ type: "SplitContent", params: [] }] },
        annotations: [],
      },
    },
  },
  RandomSplitSlice: {
    name: "RandomSplitSlice",
    base: "SplitSlice",
    concrete: false,
    fields: {
      prob: {
        name: "prob",
        type: { type: "Number", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      externalId: {
        name: "externalId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      contents: {
        name: "contents",
        type: { type: "List", params: [{ type: "SplitContent", params: [] }] },
        annotations: [],
      },
    },
  },
  SegmentSplitSlice: {
    name: "SegmentSplitSlice",
    base: "SplitSlice",
    concrete: false,
    fields: {
      cond: {
        name: "cond",
        type: { type: "String", params: [] },
        annotations: [],
      },
      uuid: {
        name: "uuid",
        type: { type: "String", params: [] },
        annotations: ["Const"],
      },
      name: {
        name: "name",
        type: { type: "String", params: [] },
        annotations: [],
      },
      externalId: {
        name: "externalId",
        type: { type: "Optional", params: [{ type: "String", params: [] }] },
        annotations: [],
      },
      contents: {
        name: "contents",
        type: { type: "List", params: [{ type: "SplitContent", params: [] }] },
        annotations: [],
      },
    },
  },
  SplitContent: { name: "SplitContent", concrete: false, fields: {} },
  GlobalVariantSplitContent: {
    name: "GlobalVariantSplitContent",
    base: "SplitContent",
    concrete: false,
    fields: {
      group: {
        name: "group",
        type: { type: "GlobalVariantGroup", params: [] },
        annotations: ["WeakRef"],
      },
      variant: {
        name: "variant",
        type: { type: "Variant", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  ComponentVariantSplitContent: {
    name: "ComponentVariantSplitContent",
    base: "SplitContent",
    concrete: false,
    fields: {
      component: {
        name: "component",
        type: { type: "Component", params: [] },
        annotations: ["WeakRef"],
      },
      group: {
        name: "group",
        type: { type: "ComponentVariantGroup", params: [] },
        annotations: ["WeakRef"],
      },
      variant: {
        name: "variant",
        type: { type: "Variant", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  ComponentSwapSplitContent: {
    name: "ComponentSwapSplitContent",
    base: "SplitContent",
    concrete: false,
    fields: {
      fromComponent: {
        name: "fromComponent",
        type: { type: "Component", params: [] },
        annotations: ["WeakRef"],
      },
      toComponent: {
        name: "toComponent",
        type: { type: "Component", params: [] },
        annotations: ["WeakRef"],
      },
    },
  },
  FigmaComponentMapping: {
    name: "FigmaComponentMapping",
    concrete: false,
    fields: {
      figmaComponentName: {
        name: "figmaComponentName",
        type: { type: "String", params: [] },
        annotations: [],
      },
    },
  },
} as const;
export const meta = new MetaRuntime(CLASSES, 3000000);
export const modelSchemaHash = 1754341105;
